import React, { useEffect, useState } from 'react'
import List from '.././List';
import Paging from '.././Paging';
import  Breadcrumb from '.././Breadcrumb';
import ListFilters from '.././ListFilters';
import axios from 'axios';
import { toast } from 'react-toastify';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
//import PUBLIC_URL+'/css/feed.css'

let params = {
    page:1,
    size: 50,
    search: '',
    source: 'afp',
    hasvideo: 1,
    startdate: '',
    enddate: ''
}

const FeedImages = () => {
    document.title = 'TV9 Portal: News Images'
    const [loading, setLoading] = useState(true);
    const [sparams, setSparams] = useState(params);
    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });

    async function getList() {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/feeds/images', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            try{
                //console.error(e.response.status, e.response.statusText);
                toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            }catch(ee) {
                toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            }
            setLoading(false);
        }
    }

    function handleClick(e){
        e.preventDefault();
        let name = e.target.name
        if (name !== 'page' && params.page > 1) params['page'] = 1
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        setSparams(prevState =>  {
            return {
                ...prevState,
                [name]:value
            }
        })
        params[name] = value
        getList();
    }

    const handleApply = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: d.startDate.format("YYYY-MM-DD"),
                enddate: d.endDate.format("YYYY-MM-DD"),
            }
        })
        params['startdate'] = d.startDate.format("YYYY-MM-DD")
        params['enddate'] = d.endDate.format("YYYY-MM-DD")
        params['page'] = 1
        getList();
    }

    const handleCancel = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: '',
                enddate: '',
            }
        })
        params['startdate'] = ''
        params['enddate'] = ''
        params['page'] = 1
        getList();
    }

    useEffect(() => {
        getList()
    }, []);

    return (
        <>
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Feeds', url:'/feed/news'}, {name:'Videos', url:''}]}/>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        Feed Videos
                                        <form className="form-inline float-right" onSubmit={e => { e.preventDefault(); }}>
                                            <div className="form-group">
                                                <label htmlFor="inlineFormInput" className="sr-only">Source</label>
                                                <select name="source" value={sparams.source} onChange={handleClick} className="mr-3 form-control">
                                                    <option value="">All Sources</option>
                                                    <option value="pti">PTI</option>
                                                    <option value="afp">AFP</option>
                                                    {/*<option value="ani">ANI</option>*/}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <DateRangePicker name="date" initialSettings={{ startDate: new Date(), endDate:  new Date() }} onApply={handleApply} onCancel={handleCancel}>
                                                    <span className="btn btn-info">{sparams.startdate && sparams.enddate? sparams.startdate +' to '+sparams.enddate:'Date'}</span>
                                                </DateRangePicker>&nbsp;&nbsp;&nbsp;
                                            </div>
                                            <ListFilters handleClick={handleClick} params={sparams}/>
                                        </form>
                                    </h4>
                                    
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <List list={list} loading={loading} heading={[
                                                {name:'#', field:'', width:''},
                                                {name:'Image', field:'imagename', width:'250', lightbox:true, captionfield:'imgdesc', download:true, typefield:'type', downloadvideo:true},
                                                {name:'Headline', field:'headline', width:'300', copyToClipboard:true, ShowMore:true}, 
                                                {name:'Story', field:'imgdesc', width:'800', copyToClipboard:true, ShowMore:true}, 
                                                {name:'Size', field:'videosize', width:'100', issize:true},
                                                {name:'Source', field:'source', width:'100'}, 
                                                {name:'Published', field:'publishtime', width:'', isdate:true}]}
                                            />
                                        <Paging handleClick={handleClick} page={list.page} loading={loading}/>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}
export default FeedImages;