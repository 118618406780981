import React, {useState, useEffect} from 'react';
import List from '.././List';
import Paging from '.././Paging';
import  Breadcrumb from '.././Breadcrumb';
import ListFilters from '.././ListFilters';

import axios from 'axios';
import { NavLink } from 'react-router-dom';
//import { toast } from 'react-toastify';

let params = {
    page:1,
    size: 20,
    order : '',
    orderdir:-1,
    search: '',    
}

const TypeList = () =>{
    document.title = 'TV9 Portal: Types'
    const [loading, setLoading] = useState(true)
    const [sparams, setSparams] = useState(params);
    const [permission, setPermission] = useState({});

    setPermission(prevState =>  {
        return {
            ...prevState,
            'settings-type':{
                view:true,
                add:true,
                edit:false,
                delete:false,
                print:false,
                download:false,
            }
        }
    })

    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });

    const viewItem = (e) => {
        e.preventDefault();
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        console.log(value, 'view')
    }
    const editItem = (e) => {
        e.preventDefault();
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        console.log(value, 'edit')
    }
    const deleteItem = (e) => {
        e.preventDefault();
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        console.log(value, 'delete')
    }

    function handleClick(e){
        e.preventDefault();
        let name = e.target.name
        if (name !== 'page' && params.page > 1) params['page'] = 1
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        setSparams(prevState =>  {
            return {
                ...prevState,
                [name]:value
            }
        })
        params[name] = value
        getList();
    }

    async function getList() {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/apis/type', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            console.error(e);
        }
    }

    // async function getPemission() {
    //     try {
    //         const response = await axios(
    //             localStorage.getItem('basurl') + '/permission', {
    //                 headers:{'Authorization':'Token '+localStorage.getItem("token")},
    //                 params: {section:'types'},
    //             }
    //         );
    //         setPermission(response.data);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    useEffect(() => {
        getList()
        //getPemission()
    }, []);

    return(
        <>
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Settings', url:'/setting/type'}, {name:'Type', url:''}]}/>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        Types
                                        <form className="form-inline float-right" onSubmit={()=>{return false;}}>
                                            <ListFilters handleClick={handleClick} params={sparams}/>
                                            {permission.add?<div className="form-group">
                                                <NavLink to="/setting/type/add" className="btn btn-primary">New</NavLink>
                                            </div>:null}                                            
                                        </form>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <List list={list} loading={loading} heading={[
                                            {name:'#', field:'', width:''},
                                            {name:'Title', field:'title', width:'300'},
                                            {name:'Short Title', field:'shorttitle', width:'800'}, 
                                            {name:'Created At', field:'createdat', width:'', isdate:true},
                                            {name:'Actions', field:'id', permission:permission, viewItem:viewItem, editItem:editItem, deleteItem:deleteItem}
                                        ]} />
                                        <Paging handleClick={handleClick} page={list.page} loading={loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}
export default TypeList;