import React from 'react';

const Error = () => {
    return (
        <>
            <div className="page login-page">
                <div className="container">
                    <div className="text-center align-items-center" style={{padding:'10% 0'}}>
                        <div className="form-inner">
                            <div className="content-wrapper align-items-center text-center error-page bg-primary">
                                <div className="row">
                                    <div className="col-lg-12 mx-auto text-white">
                                        <div className="row align-items-center d-flex flex-row">
                                            <div className="col-lg-6 pr-lg-4">
                                                <h1 className="display-1 mb-0">404</h1>
                                            </div>
                                            <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                                                <h2>SORRY!</h2>
                                                <h3 className="font-weight-light">
                                                    The page you’re looking for was not found.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-12 text-center mt-xl-2">
                                            {localStorage.getItem("token")? 
                                                <a className="text-white font-weight-medium btn btn-secondary" href="/">
                                                    Back to Home
                                                </a>
                                                :<a className="text-white font-weight-medium btn btn-secondary" href="/login">
                                                Login
                                            </a>
                                            }
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-12 mt-xl-2">
                                                <p className="text-white font-weight-medium text-center">
                                                    Copyright © 2018 All rights reserved.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Error;