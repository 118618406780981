import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = (params) => {
  let user = {
    id: 0,
    token: "",
    username: "Newsroom",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    reset: "0",
    is_staff: false,
    is_active: false,
    last_login: "",
  };
  const [manager, setManager] = useState(false);

  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
    let allowedUser = ["sachin", "hemant", "vimal", "kautilya", "kundan"];
    if (allowedUser.indexOf(user.username) > -1) {
      setTimeout(function () {
        setManager(true);
      }, 200);
    }
    if (parseInt(user.reset)) {
      window.location.href = "/reset";
    }
  }

  return (
    <>
      <nav className="side-navbar">
        <div className="side-navbar-wrapper">
          <div className="sidenav-header d-flex align-items-center justify-content-center">
            <div className="sidenav-header-inner text-center">
              <img
                src={process.env.PUBLIC_URL + `/tv9-logo.png`}
                alt="TV9"
                className=""
              />
              <h2 className="h5">
                {user.first_name
                  ? user.first_name + " " + user.last_name
                  : user.username}
              </h2>
              {/*<span>Web Developer</span>*/}
            </div>
            <div className="sidenav-header-logo">
              <a href="/" className="brand-small text-center">
                <strong>TV</strong>
                <strong className="text-primary">9</strong>
              </a>
            </div>
          </div>
          <div className="main-menu">
            <ul id="side-main-menu" className="side-menu list-unstyled">
              <li
                className={
                  params.children.props.location.pathname === "/"
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/">
                  {" "}
                  <i className="icon-home" />
                  Home
                </NavLink>
              </li>
              {user.username !== "storytailor" ? (
                <>
                  <li
                    className={
                      params.children.props.location.pathname.indexOf(
                        "/feed"
                      ) >= 0
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      href="#feed"
                      aria-expanded={
                        params.children.props.location.pathname.indexOf(
                          "/feed"
                        ) >= 0
                          ? "true"
                          : "false"
                      }
                      data-toggle="collapse"
                    >
                      <i className="icon-interface-windows"></i>
                      Feeds
                    </a>
                    <ul
                      id="feed"
                      className={
                        params.children.props.location.pathname.indexOf(
                          "/feed"
                        ) >= 0
                          ? "list-unstyled collapse show"
                          : "list-unstyled collapse"
                      }
                    >
                      <li
                        className={
                          params.children.props.location.pathname ===
                          "/feed/news"
                            ? "active"
                            : ""
                        }
                      >
                        <NavLink
                          style={{ padding: "6px 3px 6px 20px" }}
                          to="/feed/news"
                        >
                          News
                        </NavLink>
                      </li>
                      <li
                        className={
                          params.children.props.location.pathname ===
                          "/feed/images"
                            ? "active"
                            : ""
                        }
                      >
                        <NavLink
                          style={{ padding: "6px 3px 6px 20px" }}
                          to="/feed/images"
                        >
                          Images/VideoGFX
                        </NavLink>
                      </li>
                      <li
                        className={
                          params.children.props.location.pathname ===
                          "/feed/videos"
                            ? "active"
                            : ""
                        }
                      >
                        <NavLink
                          style={{ padding: "6px 3px 6px 20px" }}
                          to="/feed/videos"
                        >
                          AFP Videos
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li
                    className={
                      params.children.props.location.pathname.indexOf(
                        "/feed"
                      ) >= 0
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      href="#feed"
                      aria-expanded={
                        params.children.props.location.pathname.indexOf(
                          "/feed"
                        ) >= 0
                          ? "true"
                          : "false"
                      }
                      data-toggle="collapse"
                    >
                      <i className="icon-interface-windows"></i>
                      Feeds
                    </a>
                    <ul
                      id="feed"
                      className={
                        params.children.props.location.pathname.indexOf(
                          "/feed"
                        ) >= 0
                          ? "list-unstyled collapse show"
                          : "list-unstyled collapse"
                      }
                    >
                      <li
                        className={
                          params.children.props.location.pathname ===
                          "/feed/images"
                            ? "active"
                            : ""
                        }
                      >
                        <NavLink
                          style={{ padding: "6px 3px 6px 20px" }}
                          to="/feed/images"
                        >
                          Images/VideoGFX
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              )}
              {user.username !== "storytailor" ? (
                <>
                  <li
                    className={
                      params.children.props.location.pathname.indexOf(
                        "/sops"
                      ) >= 0
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      href="#sopmanuals"
                      aria-expanded={
                        params.children.props.location.pathname.indexOf(
                          "/sops"
                        ) >= 0
                          ? "true"
                          : "false"
                      }
                      data-toggle="collapse"
                    >
                      <i className="fa fa-cog"></i>
                      SOPs
                    </a>
                    <ul
                      id="sopmanuals"
                      className={
                        params.children.props.location.pathname.indexOf(
                          "/sops"
                        ) >= 0
                          ? "list-unstyled collapse show"
                          : "list-unstyled collapse"
                      }
                    >
                      <li
                        className={
                          params.children.props.location.pathname ===
                          "/sops/manuals/list"
                            ? "active"
                            : ""
                        }
                      >
                        <NavLink
                          style={{ padding: "6px 3px 6px 20px" }}
                          to="/sops/manuals/list"
                        >
                          All Manuals
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                ""
              )}
              {manager ? (
                <li
                  className={
                    params.children.props.location.pathname.indexOf(
                      "/reports"
                    ) >= 0
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href="#reports"
                    aria-expanded={
                      params.children.props.location.pathname.indexOf(
                        "/reports"
                      ) >= 0
                        ? "true"
                        : "false"
                    }
                    data-toggle="collapse"
                  >
                    <i className="fa fa-cog"></i>
                    Reports
                  </a>
                  <ul
                    id="reports"
                    className={
                      params.children.props.location.pathname.indexOf(
                        "/reports"
                      ) >= 0
                        ? "list-unstyled collapse show"
                        : "list-unstyled collapse"
                    }
                  >
                    <li
                      className={
                        params.children.props.location.pathname ===
                        "/reports/corporate"
                          ? "active"
                          : ""
                      }
                    >
                      <NavLink
                        style={{ padding: "6px 3px 6px 20px" }}
                        to="/reports/corporate"
                      >
                        Corporate
                      </NavLink>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Sidebar;
