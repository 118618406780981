import React, { useEffect, useLayoutEffect, useState } from 'react'
import axios from 'axios';
import List from '.././List';
import Paging from '.././Paging';
import  Breadcrumb from '.././Breadcrumb';
import ListFilters from '.././ListFilters';
import { toast } from 'react-toastify';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import queryString from 'query-string';






  
const MojoPosts = (props) => {
	
	const search = props.location.search; // could be '?foo=bar'
	const qparams = new URLSearchParams(search);
	const intid = qparams.get('id'); // bar


	let params = {
		page:1,
		size: 20,
		order : '',
		orderdir:-1,
		search: '',
		startdaintidte: '',
		enddate: '',
		id:intid
	}

	
    document.title = 'TV9 Portal: Mojo Posts'
    const [loading, setLoading] = useState(true)
	const [autorefresh, setAutoRefresh] = useState(false)
    const [sparams, setSparams] = useState(params);
    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });
    const [categories, setCategories] = useState([]);

    async function getList() {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/mojo/posts', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            try{
                //console.error(e.response.status, e.response.statusText);
                toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            }catch(ee) {
                toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            }
            setLoading(false)
        }
    }


    function handleClick(e){
        e.preventDefault();
        let name = e.target.name
        if (name !== 'page' && params.page > 1) params['page'] = 1
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        setSparams(prevState =>  {
            return {
                ...prevState,
                [name]:value
            }
        })
        params[name] = value
        getList();
    }

    const handleApply = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: d.startDate.format("YYYY-MM-DD"),
                enddate: d.endDate.format("YYYY-MM-DD"),
            }
        })
        params['startdate'] = d.startDate.format("YYYY-MM-DD")
        params['enddate'] = d.endDate.format("YYYY-MM-DD")
        params['page'] = 1
        getList();
    }

    const handleCancel = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: '',
                enddate: '',
            }
        })
        params['startdate'] = ''
        params['enddate'] = ''
        params['page'] = 1
        getList();
    }

    useEffect(() => {
        getList();
        
	    //appendSdkScript();
		
		// const executeScript =new function("<script src='https://cms.tv9.com/js/main.js'></script>)");
		// executeScript();
		
    }, []);
	if (autorefresh==false){
		setInterval(
			function(){
				getList();
			}
		, 60000);
		setAutoRefresh(true);
	}
    
	return (
        <>
			
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Mojo', url:'/mojo/posts'}, {name:'Posts', url:''}]}/>
			
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        Posts
										<form className="form-inline float-right" onSubmit={e => { e.preventDefault(); }}>
                                            <div className="form-group">
                                                <DateRangePicker name="date" initialSettings={{ startDate: new Date(), endDate:  new Date() }} onApply={handleApply} onCancel={handleCancel}>
                                                    <span className="btn btn-info">{sparams.startdate && sparams.enddate? sparams.startdate +' to '+sparams.enddate:'Date'}</span>
                                                </DateRangePicker>&nbsp;&nbsp;&nbsp;
                                            </div>
                                            <ListFilters handleClick={handleClick} params={sparams}/>
                                        </form>
									</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <List list={list} loading={loading} heading={[
                                                {name:'#', field:'', width:''},
												{name:'Link', field:'link', width:'200', lightbox:true, captionfield:'link', download:true, typefield:'type'},
                                                {name:'Title', field:'title', width:'300', copyToClipboard:true},
                                                {name:'Description', field:'description', width:'800', copyToClipboard:true, ShowMore:true}, 
												{name:'Mobile', field:'mobile_number'}, 
												{name:'By', field:'username', width:''},
                                                {name:'At', field:'created_at', width:'', isdate:true}]}
                                            />
                                        <Paging handleClick={handleClick} page={list.page} loading={loading}/>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
		
			
        </>
    )
}
export default MojoPosts;