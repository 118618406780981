
import React, { useState } from 'react';
import axios from 'axios';

const Register = (props) => {
    const qparams = new URLSearchParams(props.location.search);
    const [registered, setRegistered] = useState(false)
    const [register, setRegister] = useState({firstname:'',lastname:'', username:'', password:'', email:'', mobile:'', channel:'', error:'', redirectto:qparams.get('redirectto')})
    const [formerror, setFormError] = useState('')
    
    const changeData = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setRegister(prev =>{
            return {
                ...prev,
                [name] : value
            }
        })
        if (name === 'email') {
            setRegister(prev =>{
                return {
                    ...prev,
                    ['username'] : value.split('@')[0]
                }
            })
        }
    }

    const removeError = (e) => {
        setFormError('')
    }

    const validate = () => {
        if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(register.email))
        {
            setFormError('Please type a valid email address')
            return false;
        }
        //if (!/((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}/.test(register.mobile))
        //{
        //    setFormError('Please type a valid mobile number')
        //    return false;
        //}
        if (!/^[a-zA-Z0-9.]+$/.test(register.username))
        {
            setFormError('Please type a valid login id');
            return false;
        }
        if (!(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/).test(register.password))
        {
            setFormError('Password should be minimum 8 characters long with at list one special character, one upper case and one lower case.');
            return false;
        }
        return true;     
    }
    const getSignUp = async (e) => {
        e.preventDefault();   
        if (validate()) {
            try{
                await axios.post(
                    localStorage.getItem('basurl')+'/register/',  register
                ).then(response=>{
                    setRegistered(true)
                }).catch(err => {
                    if(err.response.data) {
                        if(err.response.data.email) {
                            setFormError('Sorry! This email address already registered with us. Please try forgot password option to recover password or contact to admin.')
                        } else if(err.response.data.username) {
                            setFormError('Sorry! This login idalready registered with us. Please try forgot password option to recover password or contact to admin.')
                        } else if(err.response.data.error) {    
                            setFormError(err.response.data.error)
                        } else {
                            setFormError(err.message)
                        }
                    } else {
                        setFormError(err.message)
                    }                    
                }); 
            } catch(err) {
                setFormError(err.message)
            }
        }
    }

    return (
        <>
           <div className="page login-page">
                <div className="container">
                    <div className="form-outer text-center align-items-center">
                        <div className="form-inner">
                            <div className="logo text-uppercase"><strong className="text-primary">NEWS9</strong><span>CMS</span></div>
                            {registered === false?
                                <>
                                <p>Please complete the registertion process to continue use this portal. Also avoid if you have already signed up</p>
                                <span className="text-danger">{formerror}</span>
                                <form method="get" className="text-left form-validate" onSubmit={getSignUp}>
                                    <div className="form-group-material">
                                        <input id="signup-firstname" type="text" name="firstname" value={register.firstname} onFocus={removeError} onChange={changeData} required data-msg="Please enter your first name" className="input-material" />
                                        <label htmlFor="signup-firstname" className="label-material"><strong>First Name <em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                    </div>
                                    <div className="form-group-material">
                                        <input id="signup-lastname" type="text" name="lastname" value={register.lastname} onFocus={removeError} onChange={changeData} data-msg="Please enter your last name" className="input-material" />
                                        <label htmlFor="signup-lastname" className="label-material"><strong>Last Name <em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                    </div>
                                    <div className="form-group-material">
                                        <input id="signup-email" type="email" name="email" value={register.email} onFocus={removeError} onChange={changeData} required data-msg="Please enter your email address" className="input-material" />
                                        <label htmlFor="signup-email" className="label-material"><strong>Email Address <em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                        <p><i>Please enter your TV9 email address</i></p>
                                    </div>
									<div className="form-group-material">
										<select id="signup-channel" name="channel" value={register.channel} onFocus={removeError} onChange={changeData} required data-msg="Please choose your channel name" className="mr-3 form-control">
											<option value="">-Channel- </option>
											<option value="Shared">Shared</option>
											<option value="Hindi">Hindi</option>
											<option value="English">English</option>
											<option value="Marathi">Marathi</option>
											<option value="Telugu">Telugu</option>
											<option value="Kannada">Kannada</option>
											<option value="Gujarati">Gujarati</option>
											<option value="Bangala">Bangala</option>
                                            <option value="Money9">Money9</option>
                                            <option value="Money9-Hindi">Money9-Hindi</option>
										</select>
                                        <p><i>Please choose channel name</i></p>
                                    </div>
                                    <div className="form-group-material">
                                        <input id="signup-mobile" type="phone" name="mobile" onFocus={removeError} onChange={changeData} data-msg="Please enter your mobile number" className="input-material" />
                                        <label htmlFor="signup-mobile" className="label-material"><strong>Mobile</strong></label>
                                    </div>									
                                    <div className="form-group-material">
                                        <input id="signup-username" type="text" name="username" value={register.username} readOnly onFocus={removeError} onChange={changeData} required data-msg="Please enter your login id" className="input-material" />
                                        <label htmlFor="signup-username" className="label-material"><strong>Login-id <em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                        <p><i>Please remember your login-id for further use</i></p>
                                    </div>
                                    <div className="form-group-material">
                                        <input id="signup-password" type="password" name="password" value={register.password} onFocus={removeError}  onChange={changeData} required data-msg="Please enter your password" className="input-material" />
                                        <label htmlFor="signup-password" className="label-material"><strong>Password <em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                        <p><i>Please remember your password for further use</i></p>
                                    </div> 
                                    <div className="form-group text-center">
                                        {register.redirectto? <a className="text-white font-weight-medium btn btn-dark" href={register.redirectto}>Back</a>:<a className="text-white font-weight-medium btn btn-dark" href="/login">Back</a>}&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-primary">SignUp</button>
                                    </div>
                                </form>
                                </>
                                :
                                <div>
                                    <br/><br/>
                                    <h4>
                                        Thank you for registering with us. We have sent an activation link on your registered email address
                                    </h4>
                                    <br/>
                                    <h5><a href="/login">Click for Login</a></h5>
                                </div>
                            }
                        </div>
                        <div className="copyrights text-center">
                            <p>TV9 <a href={{javascript:0}}>Digital Team</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Register;