import React, {useState} from 'react';
import { NavLink, Link } from 'react-router-dom'

const Header = () => {
    const logout = () => {
        if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
            if(localStorage.getItem("user")){
                localStorage.removeItem("user");
            }
            window.location.href = '/login';
        } else {
            window.location.href = '/login';
        }
    }
	
	
	
    return (
        <>
            <header className="header">
                <nav className="navbar">
                <div className="container-fluid">
                    <div className="navbar-holder d-flex align-items-center justify-content-between">
                    <div className="navbar-header">
                        <Link id="toggle-btn" role="button" tabIndex="0"  to="#" className="menu-btn"><i className="icon-bars"> </i></Link>
                        <NavLink to="/" className="navbar-brand">
                            <div className="brand-text d-none d-md-inline-block">
                                <strong className="text-primary">TV9 Portal</strong>
                            </div>
                        </NavLink></div>
                    <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                        {/*<li className="nav-item dropdown"> <a id="notifications" rel="nofollow" data-target={e => { e.preventDefault(); }} href={e => { e.preventDefault(); }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link"><i className="fa fa-bell" /><span className="badge badge-warning">12</span></a>
                        <ul aria-labelledby="notifications" className="dropdown-menu">
                            <li><a rel="nofollow" href={e => { e.preventDefault(); }} className="dropdown-item"> 
                                <div className="notification d-flex justify-content-between">
                                <div className="notification-content"><i className="fa fa-envelope" />You have 6 new messages </div>
                                <div className="notification-time"><small>4 minutes ago</small></div>
                                </div></a></li>
                            <li><a rel="nofollow" href={e => { e.preventDefault(); }} className="dropdown-item"> 
                                <div className="notification d-flex justify-content-between">
                                <div className="notification-content"><i className="fa fa-twitter" />You have 2 followers</div>
                                <div className="notification-time"><small>4 minutes ago</small></div>
                                </div></a></li>
                            <li><a rel="nofollow" href={e => { e.preventDefault(); }} className="dropdown-item"> 
                                <div className="notification d-flex justify-content-between">
                                <div className="notification-content"><i className="fa fa-upload" />Server Rebooted</div>
                                <div className="notification-time"><small>4 minutes ago</small></div>
                                </div></a></li>
                            <li><a rel="nofollow" href={e => { e.preventDefault(); }} className="dropdown-item"> 
                                <div className="notification d-flex justify-content-between">
                                <div className="notification-content"><i className="fa fa-twitter" />You have 2 followers</div>
                                <div className="notification-time"><small>10 minutes ago</small></div>
                                </div></a></li>
                            <li><a rel="nofollow" href={e => { e.preventDefault(); }} className="dropdown-item all-notifications text-center"> <strong> <i className="fa fa-bell" />view all notifications</strong></a></li>
                        </ul>
                        </li>
                        <li className="nav-item dropdown"><a id="languages" rel="nofollow" data-target="#" href={e => { e.preventDefault(); }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link language dropdown-toggle"><img src={process.env.PUBLIC_URL+`/img/flags/16/GB.png`} alt="English" /><span className="d-none d-sm-inline-block">English</span></a>
                            <ul aria-labelledby="languages" className="dropdown-menu">
                                <li><a rel="nofollow" href={e => { e.preventDefault(); }} className="dropdown-item"> <img src={process.env.PUBLIC_URL+`/img/flags/16/DE.png`} alt="English" className="mr-2" /><span>German</span></a></li>
                                <li><a rel="nofollow" href={e => { e.preventDefault(); }} className="dropdown-item"> <img src={process.env.PUBLIC_URL+`/img/flags/16/FR.png`} alt="English" className="mr-2" /><span>French                                                       </span></a></li>
                            </ul>
                        </li>*/}
                        <li className="nav-item"><Link onClick={logout} to="#" className="nav-link logout"> <span className="d-none d-sm-inline-block">Logout</span><i className="fa fa-sign-out" /></Link></li>
                    </ul>
                    </div>
                </div>
                </nav>
            </header>
        </>
    )
}
export default Header;