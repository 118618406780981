import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Home = () => {
  document.title = "TV9 CMS Portal: Home";
  var token = "?token=" + localStorage.getItem("token");
  //window.location.href ='/feed/news'

  let user = {
    id: 0,
    token: "",
    username: "Newsroom",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    reset: "0",
    is_staff: false,
    is_active: false,
    last_login: "",
  };

  const [mojouser, setMojoUser] = useState(false);

  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));

    let allowedMojoUser = [
      "sachin.tyagi",
      "hemant",
      "kautilya",
      "piyush",
      "bvrao",
    ];
    if (allowedMojoUser.indexOf(user.username) > -1) {
      setTimeout(function () {
        setMojoUser(true);
      }, 200);
    }

    if (parseInt(user.reset)) {
      window.location.href = "/reset";
    }
  }

  return (
    <>
      {user.username !== "storytailor" ? (
        <>
          <div class="row homepage" style={{ padding: "20px 40px" }}>
            <div class="col-md-3">
              <table class="table">
                <tr class="btn-success text-center">
                  <th>Feeds</th>
                </tr>
                <tr class="alert-success">
                  <th>
                    <ul>
                      <li>
                        <NavLink to="/feed/news">PTI and AFP Text</NavLink>
                      </li>
                      <li>
                        <NavLink to="/feed/images">Images / Video GFX</NavLink>
                      </li>
                      <li>
                        <NavLink to="/feed/videos">AFP Videos</NavLink>
                      </li>

                      {/*<li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/Money9/cmots.aspx' + token} >Business News</a></li>
				<li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/Analytics/News9_fb.aspx' + token}>News9 FB Archive</a></li>
				<li><a rel="noopener noreferrer" target="_blank" href={'https://digital.tv9.com/analytics_offline/TV9AlertFeed.aspx' + token}>TV9 Shared Stories</a></li>
				<li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/analytics_offline/RedditStories.aspx' + token}>Reddit Stories</a></li>
				*/}
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/Money9/OctopusFeed.aspx" +
                            token
                          }
                        >
                          Bharatvarsh TV Feed
                        </a>
                      </li>
					  
					  

                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "https://e.tv9hindi.com/admin/scorecard" + token
                          }
                        >
                          Cricket Scorecard - iframe
                        </a>
                      </li>
                      
                    </ul>
                  </th>
                </tr>
              </table>
            </div>
            <div class="col-md-3">
              <table class="table">
                <tr class="btn-success text-center">
                  <th>Analytics</th>
                </tr>
                <tr class="alert-success">
                  <th>
                    <ul>
                      {/*<li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/analytics_offline/GA_Summary.aspx' + token}>GA Summary</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/analytics_offline/GA_Realtime.aspx' + token}>GA Realtime</a></li>*/}
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/analytics_offline/WebStoryUserCatCount.aspx" +
                            token
                          }
                        >
                          Web Story/Post Count
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/analytics_offline/ActiveUsers.aspx" +
                            token
                          }
                        >
                          Author Performance
                        </a>
                      </li>
                      {/*<li><a rel="noopener noreferrer" target="_blank" href={'http://trends.tv9.com:8080/seo/youTubeDashboard' + token}>Youtube Dashboard</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://trends.tv9.com:8080/seo/youtubeanalytics' + token}>Youtube Video Analytics</a></li>*/}
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/Analytics_offline/CompetitionChannels.aspx" +
                            token
                          }
                        >
                          Competition Channels
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/Analytics_offline/CompetitionChannelRobots.aspx" +
                            token
                          }
                        >
                          Competition Robots
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/Analytics_offline/CacheInvalidation.aspx" +
                            token
                          }
                        >
                          Cache Invalidation
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/Analytics/GA_StoryCountDashboard.aspx" +
                            token
                          }
                        >
                          Story Count Dashboard
                        </a>
                      </li>
                    </ul>
                  </th>
                </tr>
              </table>
            </div>
            {/* <div class="col-md-3">
          <table class='table'>
            <tr class="btn-success text-center"><th>Analytics</th></tr>
            <tr class="alert-success"><th>
              <ul>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/analytics' + token}>Analytics All</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/Hindi' + token}>Hindi Analytics</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/Kannada' + token}>Kannada Analytics</a> </li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/Marathi' + token}>Marathi Analytics</a></li>
                
                
              </ul>
            </th></tr>
          </table>
        </div> */}
            {/* <div class="col-md-3">
          <table class='table'>
            <tr class="btn-success text-center"><th>Elections</th></tr>
            <tr class="alert-success"><th>
              <ul>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com' + token}>Punjab Coucil Election</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/AndhraPanchayat' + token}>Andhra Punjab Elections</a></li>
              </ul>
            </th></tr>
          </table>
        </div> */}

            {/* </div>

      <div class="row"> */}

            {/*<div class="col-md-3">
          <table class='table'>
            <tr class="btn-success text-center"><th>SEO</th></tr>
            <tr class="alert-success"><th>
              <ul>
				<li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/analytics_offline/SEOReport.aspx' + token}>SEO Check</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://trends.tv9.com:8080/seo/googleTrend' + token}>Google Trends</a></li>
                <li><a rel="noopener noreferrer" target="_blank" href={'http://trends.tv9.com:8080/seo/twittertrends' + token}>Twitter Trends</a></li>
              </ul>
            </th></tr>
          </table>
        </div>*/}

            <div class="col-md-3">
              <table class="table">
                <tr class="btn-success text-center">
                  <th>News</th>
                </tr>
                <tr class="alert-success">
                  <th>
                    <ul>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={"https://digital.tv9.com/NewsPulse/" + token}
                        >
                          News Pulse
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "https://digital.tv9.com/Analytics_offline/(S(oo2fb1tqdj0yekiebf1r24ju))/ChannelTrend.aspx" +
                            token
                          }
                        >
                          News Pulse Channel Trend
                        </a>
                      </li>
					  {/* <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "https://digital.tv9.com/sharedfeed/TV9Feed.aspx" +
                            token
                          }
                        >
                          TV9 News Feed
                        </a>
                      </li> */}
					  <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "https://digital.tv9.com/sharedfeed/TV9AlertFeed.aspx" +
                            token
                          }
                        >
                          TV9 Shared News
                        </a>
                      </li>
					  {/* <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "https://digital.tv9.com/sharedfeed/TV9SharedFeedURL.aspx" +
                            token
                          }
                        >
                          Update News URL 
                        </a>
                      </li> */}
					  <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "https://digital.tv9.com/sharedfeed/TV9SharedFeedReport.aspx" +
                            token
                          }
                        >
                          Shared News Report
                        </a>
                      </li>
					  
					  <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "https://digital.tv9.com/Analytics/(S(ya4llgay0mpdpjioqkwxtfzw))/yt/ytentrynew.aspx" +
                            token
                          }
                        >
                          YT/JW Upload
                        </a>
                      </li>
					  <li><a rel="noopener noreferrer" target="_blank" href={'https://digital.tv9.com/Analytics/(S(ya4llgay0mpdpjioqkwxtfzw))/JWPlayer_Realtime.aspx' + token}>JW Realtime</a></li> 

                      {/* <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/ipl' + token}>IPL Auction</a></li> */}
                      {/* <li><a rel="noopener noreferrer" target="_blank" href='/sops/manuals/list'>SOPs (All Manuals)</a></li> */}
                      {/* <li><a rel="noopener noreferrer" target="_blank" href='/reports/corporate'>Reports > Corporate</a></li> */}
                    </ul>
                  </th>
                </tr>
              </table>
            </div>
            <div class="col-md-3">
              <table class="table">
                <tr class="btn-success text-center">
                  <th>Utilities</th>
                </tr>
                <tr class="alert-success">
                  <th>
                    <ul>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/BookMeetings/PhoneExt.aspx" +
                            token
                          }
                        >
                          Noida 125 Extensions
                        </a>
                      </li>
                      {/* <li><a rel="noopener noreferrer" target="_blank" href={'http://digital.tv9.com/ipl' + token}>IPL Auction</a></li> */}
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="/sops/manuals/list"
                        >
                          SOPs (All Manuals)
                        </a>
                      </li>
                      {/* <li><a rel="noopener noreferrer" target="_blank" href='/reports/corporate'>Reports > Corporate</a></li> */}
                    <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/InventoryTracker/Default.aspx" +
                            token
                          }
						 >
                          Inventory Tracker
                        </a>
                      </li>
					  {/* <li><a rel="noopener noreferrer" target="_blank" href='/reports/corporate'>Reports > Corporate</a></li> */}
					   <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            "http://digital.tv9.com/HardwareTracker/Default.aspx" +
                            token
                          }
						 >
                          Hardware Tracker
                        </a>
                      </li>
					</ul>
                  </th>
                </tr>
              </table>
            </div>
          </div>
          <script src={process.env.PUBLIC_URL + "/js/front.js"} />
        </>
      ) : (
        <ul>
          <li>
            <NavLink to="/feed/images">Images / Video GFX</NavLink>
          </li>
        </ul>
      )}
    </>
  );
};
export default Home;
