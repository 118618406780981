import React from 'react';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    const copyrightyear = new Date().year;
    return (
        <>
            <footer className="main-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-3">
                            <p>TV9 © {copyrightyear}</p>
                        </div>
						<div className="col-sm-6"  style={{color: "white"}}>
							<label class="h6">PTI Alerts &nbsp;</label>
							<select id="notCat" style={{height: "28pt",fontSize : "xx-small"}} multiple>
								<option value="NEWSALERTS" selected>NEWSALERTS</option>
								<option value="BUSINESS">BUSINESS</option>
								<option value="ENTERTAINMENT">ENTERTAINMENT</option>
								<option value="NATIONAL">NATIONAL</option>
								<option value="SPORTS">SPORTS</option>
							</select>&nbsp;
							<button class="js-push-btn btn btn-xs alert-success">...</button>
						</div>
                        <div className="col-sm-3 text-right">
							<p>Design by <Link to="#" className="external" role="button">TV9 Digital Team</Link></p>
							{/* Please do not remove the backlink to us unless you support further theme's development at https://bootstrapious.com/donate. It is part of the license conditions and it helps me to run Bootstrapious. Thank you for understanding :)*/}
                        </div>
                    </div>
                

				</div>
            </footer>
			
        </>
    ) 
}
export default Footer;