import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Activate = (props) => {
    const qparams = new URLSearchParams(props.location.search);
    let username = props.match.params.username;
    const [redirect] = useState({to:qparams.get('redirectto')})
    const [actiavted, setActivated] = useState(false)
    const [formerror, setFormError] = useState('')
    
    useEffect((props)=>{
        axios.post(
            localStorage.getItem('basurl')+'/activate/', {
                username: (localStorage.getItem("user")?(JSON.parse(localStorage.getItem("user"))).username:(props)?(props.match.params.username):username)
            }
        ).then(response=>{
            setActivated(true)
        }).catch(error => {
            try{
                let err = error.response.data;
                if (err.non_field_errors) {
                    setFormError(err.non_field_errors)
                }
            } catch(err) {
                console.error(error.message);
                setFormError(error.message)
            }                        
            return;
        });   
    }, [])

    return (
        <>
           <div className="page login-page">
                <div className="container">
                    <div className="form-outer text-center align-items-center">
                        <div className="form-inner">
                            <div className="logo text-uppercase"><strong className="text-primary">NEWS9</strong><span>CMS</span></div>
                            <p>Please reset your password to continue using this portal</p>
                            {actiavted?
                                <>
                                    <br/><br/>
                                    <h4>
                                        Your account has been activated successfully. You can now login in to your account.  
                                    </h4>
                                    <br/>
                                    {redirect.to && redirect.to != 'None'?<h5><a href={redirect.to}>Please click here to login </a></h5>:<h5><a href="/login">Please click here to login </a></h5>}
                                </>:
                                <>
                                    <h4>Please wait while are actiavting your account...</h4>
                                    <span className="text-danger">{formerror}</span>
                                </>
                            }
                        </div>
                        <div className="copyrights text-center">
                            <p>TV9 <a href={{javascript:0}}>Digital Team</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Activate;