import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const Frontend = (props) => {
    return (
        <>
            <React.Fragment>
                <Sidebar {...props}/>
                <div className="page">
                    <Header />
                    {props.children}
                    <Footer props />
                </div>
            </React.Fragment>
        </>
    )
}
export default Frontend;