import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router,} from 'react-router-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
//import * as serviceWorker from './sw.js';
//import LocalServiceWorkerRegister from './sw-register';



ReactDOM.render(
  <>
    <Router>
      <App />
    </Router>    
	
  </>,
  document.getElementById('root') 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//LocalServiceWorkerRegister();





const applicationServerPublicKey = 'BFazbM0ie5YV2CflEtGQEtESWReBE-rPI21ZethxOmPdblsNs78FkQM-lzXrHKTAEuKhRJiqiizfOiWFkkjzUPU';
// privatekey- URYb6TuBDku49BgMiS35aC1EDWNlRHhHJKTu3OSdIPg

const pushButton = document.querySelector('.js-push-btn');

let isSubscribed = false;
let swRegistration = null;

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


if ('serviceWorker' in navigator && 'PushManager' in window) {
  console.log('Service Worker and Push is supported');
  //serviceWorker1.register()
   navigator.serviceWorker.register('https://cms.tv9.com/js/sw.js')
    .then(function (swReg) {
      console.log('Service Worker is registered', swReg);

      swRegistration = swReg;
      initializeUI();
    })
    .catch(function (error) {
      console.error('Service Worker Error', error);
    });
} else {
  console.warn('Push messaging is not supported');
  pushButton.textContent = 'Push Not Supported';
}


function initializeUI() {
  pushButton.addEventListener('click', function () {
    pushButton.disabled = true;
    if (isSubscribed) {
      unsubscribeUser();
    } else {
      subscribeUser();
    }
  });

  // Set the initial subscription value
  swRegistration.pushManager.getSubscription()
    .then(function (subscription) {
      isSubscribed = !(subscription === null);

      if (isSubscribed) {
        console.log('User IS subscribed.');
      } else {
        console.log('User is NOT subscribed.');
      }

      updateBtn();
    });
}

function updateBtn() {
  if (Notification.permission === 'denied') {
    pushButton.textContent = 'Push Messaging Blocked.';
    pushButton.disabled = true;
    updateSubscriptionOnServer(null);
    return;
  }

  if (isSubscribed) {
    pushButton.textContent = 'Disable Push Messaging';
	document.querySelector('.js-push-btn').style.backgroundColor = "#f8d7da";
  } else {
    pushButton.textContent = 'Enable Push Messaging';
	document.querySelector('.js-push-btn').style.backgroundColor = "#d4edda";
  }

  pushButton.disabled = false;
}


function subscribeUser() {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  swRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey
  })
    .then(function (subscription) {
      console.log('User is subscribed.');

      updateSubscriptionOnServer(subscription);

      isSubscribed = true;

      updateBtn();
    })
    .catch(function (err) {
      console.log('Failed to subscribe the user: ', err);
      updateBtn();
    });
}

function unsubscribeUser() {
  try {
    swRegistration.pushManager.getSubscription()
      .then(function (subscription) {
        if (subscription) {
          return subscription.unsubscribe();
        }
      })
      .catch(function (error) {
        console.log('Error unsubscribing', error);
      })
      .then(function () {
        updateSubscriptionOnServer(null);

        console.log('User is unsubscribed.');
        isSubscribed = false;

        updateBtn();
      });
  }
  catch {

  }
}

function updateSubscriptionOnServer(subscription) {
  // TODO: Send subscription to application server
  if (subscription) {
    sendSubscriptionToBackEnd(subscription)
  }
}


function sendSubscriptionToBackEnd(subscription) {
  console.log("sendSubscriptionToBackEnd");
  var ip=httpGet('https://apis.tv9.com/apis/getip');
  var userdata = localStorage.getItem("user");
  var obj=JSON.parse(userdata);
  console.log(obj.username);
  
  var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  var strDevice="";
  if (isMobile) {
	strDevice="Mobile";
  } else {
	strDevice="Desktop";
  }
  Array.from(document.querySelector("#notCat").options).forEach(function(option_element) {
    let option_text = option_element.text;
    let option_value = option_element.value;
    let is_option_selected = option_element.selected;
	if (is_option_selected==true){
		console.log(option_value);	
		const formData  = new FormData();
		formData.append('subscriptioninfo', JSON.stringify(subscription));
		formData.append('type', 'PTI');
		formData.append('category', option_value);
		formData.append('ip', ip);
		formData.append('name', obj.username);
		formData.append('device', strDevice);
		
		return fetch('https://apis.tv9.com/apis/subscription', {
			method: 'POST',
			body: formData
		  })
		.then(function(response) {
			if (!response.ok) {
			  throw new Error('Bad status code from server.');
			}

			return response.json();
		  })
		  .then(function(responseData) {
			if (!(responseData.data && responseData.data.success)) {
			  throw new Error('Bad response from server.');
			}
		  });

		
	}
	});  
}

function httpGet(theUrl)
{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
    xmlHttp.send( null );
    return xmlHttp.responseText;
}

