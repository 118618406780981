import React, {useState, useEffect} from 'react';
import List from '.././List';
import Paging from '.././Paging';
import  Breadcrumb from '.././Breadcrumb';
import ListFilters from '.././ListFilters';
import axios from 'axios'
//import { toast } from 'react-toastify';

let params = {
    page:1,
    size: 20,
    order : '',
    orderdir:-1,
    search: '',
    language: '',
    source: 'pti',
    category: '',
    priority: '',
    startdate: '',
    enddate: ''
}

const LanguageList = () =>{
    document.title = 'TV9 Portal: Languages'
    const [loading, setLoading] = useState(true)
    const [sparams, setSparams] = useState(params);
    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });

    function handleClick(e){
        e.preventDefault();
        let name = e.target.name
        if (name !== 'page' && params.page > 1) params['page'] = 1
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        setSparams(prevState =>  {
            return {
                ...prevState,
                [name]:value
            }
        })
        params[name] = value
        getList();
    }

    async function getList() {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/apis/language', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            console.error(e);
        }
    }
    useEffect(() => {
        getList()
    }, []);

    return(
        <>
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Settings', url:'/setting/type'}, {name:'Language', url:''}]}/>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        Languages
                                        <form className="form-inline float-right" onSubmit={()=>{return false;}}>
                                            <ListFilters handleClick={handleClick} params={sparams}/>
                                        </form>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <List list={list} loading={loading} heading={[
                                            {name:'#', field:'', width:''},
                                            {name:'Title', field:'title', width:'300'},
                                            {name:'Short Title', field:'shorttitle', width:'800'}, 
                                            {name:'Created At', field:'createdat', width:'', isdate:true}]}
                                        />
                                        <Paging handleClick={handleClick} page={list.page} loading={loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}
export default LanguageList;