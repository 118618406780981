import React, { useLayoutEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Backend from './layouts/Backend';
import Blank from './layouts/Blank';

import Home from './pages/home/Home';
import Error from './pages/Error';
import Login from './pages/Login';
import Reset from './pages/Reset';
import Activate from './pages/Activate';
import Forgot from './pages/Forgot';
import Register from './pages/Register';

import FeedNews from './pages/feed/FeedNews'; 
import FeedImages from './pages/feed/FeedImages.jsx';
import FeedVideos from './pages/feed/FeedVideos.jsx';

import MojoPosts from './pages/mojo/MojoPosts.jsx';

import MojoUsers from './pages/mojo/MojoUsers.jsx';

import TypeList from './pages/setting/TypeList';
import Add from './pages/setting/Add';
import LanguageList from './pages/setting/LanguageList';
import CategoryList from './pages/setting/CategoryList';

import Corporate from './pages/reports/Corporate';
import Schedules from './pages/mailer/Schedules';
import SopManuals from './pages/sops/SopManuals';

import 'react-toastify/dist/ReactToastify.css';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout><Component {...props} /></Layout>)}>
  </Route>
)

const App = (props) => {
  let user = {
    id:0,
    token: "",
    username: "Newsroom",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    reset : "0",
    is_staff: false,
    is_active: false,
    last_login: ""
  }
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    localStorage.setItem('basurl', 'http://127.0.0.1:8000')
  } else {
    //localStorage.setItem('basurl', 'http://15.207.241.76:8000')
	if(process.env.HTTPS) {
		localStorage.setItem('basurl', 'https://apis.tv9.com')
	} else {
		localStorage.setItem('basurl', 'https://apis.tv9.com')
	}
  }

  useLayoutEffect(()=>{
    if (!localStorage.getItem("token")) {
      if(window.location.href.indexOf('/login') <= 0 
        && window.location.href.indexOf('/signup') <= 0 
        && window.location.href.indexOf('/reset') <= 0 
        && window.location.href.indexOf('/activate') <= 0 
        && window.location.href.indexOf('/forgot-password') <= 0
        ) {
         window.location.href = '/login';
      }
    } else {
      if(window.location.href.indexOf('/reset') <= 0 ) {
        if (localStorage.getItem("user")) {
          user = (JSON.parse(localStorage.getItem("user")));
          if(user.reset) {
            window.location.href = '/reset';
          }
        }
      }
    }
  })

  return (
    <>     
      <Switch>
        <AppRoute path="/" exact layout={Backend} component={Home} />
        <AppRoute path="/login" layout={Blank} component={Login} />
        <AppRoute path="/reset" exact layout={Blank} component={Reset}/>
        <AppRoute path="/reset/:email" layout={Blank} component={Reset}/>
        <AppRoute path="/activate/:username" layout={Blank} component={Activate}/>
        <AppRoute path="/forgot-password" layout={Blank} component={Forgot} />          
        <AppRoute path="/signup" layout={Blank} component={Register} />
        
        <AppRoute path="/feed/news" layout={Backend} component={FeedNews} />
        <AppRoute path="/feed/images" layout={Backend} component={FeedImages} />
        <AppRoute path="/feed/videos" layout={Backend} component={FeedVideos} />
		
		<AppRoute path="/mojo/posts" layout={Backend} component={MojoPosts} />
		<AppRoute path="/mojo/users" layout={Backend} component={MojoUsers} />
        
        <AppRoute path="/setting/type" exact layout={Backend} component={TypeList} />
        <AppRoute path="/setting/type/add" exact layout={Backend} component={Add} />
        <AppRoute path="/setting/type/edit/:id" layout={Backend} component={TypeList} />
        
        <AppRoute path="/setting/language" layout={Backend} component={LanguageList} />
        <AppRoute path="/setting/language/add" layout={Backend} component={TypeList} />
        <AppRoute path="/setting/language/edit/:id" layout={Backend} component={TypeList} />
        
        <AppRoute path="/setting/category" layout={Backend} component={CategoryList} />
        <AppRoute path="/setting/category/add" layout={Backend} component={TypeList} />
        <AppRoute path="/setting/category/edit/:id" layout={Backend} component={TypeList} />

        <AppRoute path="/mailer/schedules" layout={Backend} component={Schedules} />

        <AppRoute path="/reports/corporate" layout={Backend} component={Corporate} />

        <AppRoute path="/sops/manuals/list" layout={Backend} component={SopManuals} />
        
        <Switch>
          <AppRoute path="*" exact layout={Blank} component={Error} />
        </Switch>
      </Switch>     
      <ToastContainer position="top-center"/>
    </>
  )
}

export default App;
