import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';

const Forgot = (props) => {
    const qparams = new URLSearchParams(props.location.search);
    const [loading, setLoading] = useState(false);
    const [forgotten, setForgotten] = useState(false)
    const [forgot, setForgot] = useState({email:'', error:'', redirectto:qparams.get('redirectto')})
    const [formerror, setFormError] = useState('')
    const [toppeding, setToppeding] = useState(0)

    const changeData = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setForgot(prev =>{
            return {
                ...prev,
                [name] : value
            }
        })
    }

    const removeError = (e) => {
        setFormError('')
    }

    const getForgot = async (e) => {
        e.preventDefault();
        if (forgot.email !=='') {
            try {
                setLoading(true);
                await axios.post(
                    localStorage.getItem('basurl')+'/forgot/', forgot,
                ).then(response=>{
                    setForgotten(true)
                   setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    try{
                        let err = error.response.data;
                        if (err.non_field_errors) {
                            setFormError(err.non_field_errors)
                        }
                        if (err.error) {
                            setFormError(err.error)
                        }
                    } catch(err) {
                        console.error(error.message);
                        setFormError(error.message)
                    }                        
                    return;
                });                
            } catch(err) {
                setLoading(false);
                console.error(err.message);
                setFormError(err.message)
            }
        } else {
            setLoading(false);
            setFormError('Please enter valid email address')
        }        
    }

    useLayoutEffect(()=>{
        setToppeding(((document.documentElement.clientHeight/document.documentElement.scrollHeight)*100)/5)
    }, [])

    return (
        <>
           <div className="page login-page">
                <div className="container">
                    <div className="form-outer text-center align-items-center" style={{padding: parseInt(toppeding-10)+'% 0'}}>
                        <div className="form-inner">
                            <div className="logo text-uppercase"><strong className="text-primary">NEWS9</strong><span>CMS</span></div>
                            <p>Forgot Password?</p>
                            {forgotten?
                                <>
                                    <br/>
                                    <h5>
                                        An email with the reset pasword link has been sent on your register email address. Please check in your mail box and click on reset password link.    
                                    </h5>
                                    <br/>
                                    <h5>
                                        {forgot.redirectto? <a href={forgot.redirectto}>Back</a>:<a href="/login">Back</a>}
                                    </h5>
                                </>:
                                <>
                                <span className="text-danger">{formerror}</span>
                                <form method="get" className="text-left form-validate" onSubmit={getForgot}>
                                    <div className="form-group-material">
                                        <input id="login-email" type="email" name="email" value={forgot.email} onFocus={removeError}  onChange={changeData} required data-msg="Please enter your registered email address" className="input-material" />
                                        <label htmlFor="login-email" className="label-material"><strong>Email<em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                        <p><i>Please enter your TV9 email address</i></p>
                                    </div>
                                    <div className="form-group text-center">
                                        {forgot.redirectto? <a className="btn btn-dark" href={forgot.redirectto}>Back</a>:<a className="btn btn-dark" href="/login">Back</a>}&nbsp;&nbsp;&nbsp;&nbsp;
                                        {loading?<div className="spinner-border text-success" role="status" style={{margin: '0px 14px -7px'}}>
                                        <span className="sr-only">Loading...</span>
                                        </div>:<button type="submit" className="btn btn-primary">Reset</button>}
                                    </div>
                                </form>
                                </>
                            }
                        </div>
                        <div className="copyrights text-center">
                            <p>TV9 <a href={{javascript:0}}>Digital Team</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Forgot;