import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';

const Reset = (props) => {
    const username = (JSON.parse(localStorage.getItem("user")))?(JSON.parse(localStorage.getItem("user"))).username:props.match.params.email
    const qparams = new URLSearchParams(props.location.search);
    if(!username){
        //window.location.href = '/login'
    }
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false)
    const [reset, setReset] = useState({password:'', repassword:'', error:'', redirectto:qparams.get('redirectto')})
    const [formerror, setFormError] = useState('')
    const [toppeding, setToppeding] = useState(0)

    const changeData = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setReset(prev =>{
            return {
                ...prev,
                [name] : value
            }
        })
    }

    const removeError = (e) => {
        setFormError('')
    }

    const getReset = async (e) => {
        e.preventDefault();
        if (reset.password !=='' && reset.repassword !=='' && reset.password === reset.repassword) {
            if (!(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/).test(reset.password))
            {
                setLoading(false);
                setFormError('Password should be minimum 8 characters long with at list one special character, one upper case and one lower case.')
            } else {
                try {
                    setLoading(true);
                    await axios.post(
                        localStorage.getItem('basurl')+'/reset/', {
                            username: username, 
                            password: reset.password
                        }
                    ).then(response=>{
                        setChanged(true)
                        if (localStorage.getItem("token")) {
                            localStorage.removeItem("token");
                        }
                        if (localStorage.getItem("user")) {
                            localStorage.removeItem("user");
                        } 
                        setLoading(false);
                    }).catch(error => {
                        setLoading(false);
                        try{
                            let err = error.response.data;
                            if (err.non_field_errors) {
                                setFormError(err.non_field_errors)
                            }
                            if (err.error) {
                                setFormError(err.error)
                            }
                        } catch(err) {
                            console.error(error.message);
                            setFormError(error.message)
                        }                        
                        return;
                    });                
                } catch(err) {
                    setLoading(false);
                    console.error(err.message);
                    setFormError(err.message)
                }
            }
        } else {
            setLoading(false);
            setFormError('Password and re-password should be same.')
        }
    }

    useLayoutEffect(()=>{
        setToppeding(((document.documentElement.clientHeight/document.documentElement.scrollHeight)*100)/5)
    }, [])

    return (
        <>
           <div className="page login-page">
                <div className="container">
                    <div className="form-outer text-center align-items-center" style={{padding: parseInt(toppeding-10)+'% 0'}}>
                        <div className="form-inner">
                            <div className="logo text-uppercase"><strong className="text-primary">NEWS9</strong><span>CMS</span></div>
                            <p>Please reset your password to continue using this portal</p>
                            {changed?
                                <>
                                    <br/><br/>
                                    <h4>
                                        Your password has been changed successfully.  
                                    </h4>
                                    <br/>
                                    {reset.redirectto?<h5><a href={reset.redirectto}>Please click here to login</a></h5>: <h5><a href="/login">Please click here to login</a></h5>}
                                </>:
                                <>
                                <span className="text-danger">{formerror}</span>
                                <form method="get" className="text-left form-validate" onSubmit={getReset}>
                                    <div className="form-group-material">
                                        <input id="login-password" type="password" name="password" value={reset.password} onFocus={removeError}  onChange={changeData} required data-msg="Please enter your password" className="input-material" />
                                        <label htmlFor="login-password" className="label-material"><strong>Password<em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                    </div>
                                    <div className="form-group-material">
                                        <input id="login-repassword" type="password" name="repassword" value={reset.repassword} onFocus={removeError}  onChange={changeData} required data-msg="Please re enter your password" className="input-material" />
                                        <label htmlFor="login-repassword" className="label-material"><strong>Re-Password<em style={{color:'red', fontSize:'23px',lineHeight:'1px'}}>*</em></strong></label>
                                    </div>
                                    <div className="form-group text-center">
                                        {loading?<div className="spinner-border text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </div>:<button type="submit" className="btn btn-primary">Reset</button>}
                                    </div>
                                </form>
                                </>
                            }
                        </div>
                        <div className="copyrights text-center">
                            <p>TV9 <a href={{javascript:0}}>Digital Team</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Reset;