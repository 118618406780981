import React, { useEffect, useState } from 'react'
import axios from 'axios';
import List from '.././List';
import Paging from '.././Paging';
import  Breadcrumb from '.././Breadcrumb';
import ListFilters from '.././ListFilters';
import { toast } from 'react-toastify';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './mailer.css'

let params = {
    page:1,
    size: 20,
    order : '',
    orderdir:-1,
    search: '',
    sent: '',
    startdate: '',
    enddate: ''
}

const Schedules = () => {
    document.title = 'TV9 Portal: Email Schedules'
    const [loading, setLoading] = useState(true)
    const [sparams, setSparams] = useState(params);
    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });
    
    async function getList() {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/mailer/schedule', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            try{
                //console.error(e.response.status, e.response.statusText);
                toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            }catch(ee) {
                toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            }
            setLoading(false)
        }
    }

    function handleClick(e){
        e.preventDefault();
        let name = e.target.name
        if (name !== 'page' && params.page > 1) params['page'] = 1
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        setSparams(prevState =>  {
            return {
                ...prevState,
                [name]:value
            }
        })
        params[name] = value
        getList();
    }

    const handleApply = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: d.startDate.format("YYYY-MM-DD"),
                enddate: d.endDate.format("YYYY-MM-DD"),
            }
        })
        params['startdate'] = d.startDate.format("YYYY-MM-DD")
        params['enddate'] = d.endDate.format("YYYY-MM-DD")
        params['page'] = 1
        getList();
    }

    const handleCancel = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: '',
                enddate: '',
            }
        })
        params['startdate'] = ''
        params['enddate'] = ''
        params['page'] = 1
        getList();
    }

    useEffect(() => {
        getList()
    }, []);

    return (
        <>
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Mailer', url:'/mailer/schedules'}, {name:'Schedules', url:''}]}/>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        Email Schedules
                                        <form className="form-inline float-right" onSubmit={e => { e.preventDefault(); }}>
                                            
                                            <div className="form-group">
                                                <label htmlFor="inlineFormInput" className="sr-only">Status</label>
                                                <select name="sent" value={sparams.sent} onChange={handleClick} className="mr-3 form-control">
                                                    <option value="">All</option>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Sent</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <DateRangePicker name="date" initialSettings={{ startDate: new Date(), endDate:  new Date() }} onApply={handleApply} onCancel={handleCancel}>
                                                    <span className="btn btn-info">{sparams.startdate && sparams.enddate? sparams.startdate +' to '+sparams.enddate:'Date'}</span>
                                                </DateRangePicker>&nbsp;&nbsp;&nbsp;
                                            </div>
                                            <ListFilters handleClick={handleClick} params={sparams}/>
                                        </form>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <List list={list} loading={loading} heading={[
                                                {name:'#', field:'', width:''},
                                                {name:'To', field:'to', width:'10%', class:'mailto',copyToClipboard:true, replace:[',', ', ']},
                                                {name:'Cc', field:'cc', width:'10%', class:'mailcc', replace:[',', ', ']},
                                                {name:'Bcc', field:'bcc', width:'10%', class:'mailbcc', replace:[',', ', ']},
                                                {name:'Subject', field:'subject', width:'30%', copyToClipboard:true, ShowMore:true}, 
                                                {name:'Body', field:'body', width:'40%', copyToClipboard:true, ShowMore:true},
                                                {name:'Status', field:'sent', width:'', yesno:true}, 
                                                {name:'ScheduledAt', field:'scheduleat', width:'', isdate:true},
                                                {name:'SentAt', field:'sentat', width:'', isdate:true, timeZone:'Europe/London'}]}
                                            />
                                        <Paging handleClick={handleClick} page={list.page} loading={loading}/>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Schedules;