import React from 'react';

const Paging = (props) => {

    const pages = (currentPage, totalPage) => {
        let startPage = currentPage - 4;
        let endPage = currentPage + 4;
        if (startPage <= 0) {
            endPage -= (startPage - 1);
            startPage = 1;
        }
        if (endPage > totalPage)
            endPage = totalPage;

        let list =[]
        if (startPage > 1) list.push('First')
        for(let i=startPage; i<=endPage; i++) list.push(i)
        if (endPage < totalPage) list.push('Last')
        return list
    }

    const page = (i) => {
        if(i === parseInt(props.page.current)) return <li key={`paging-li-`+i} className="page-item disabled"><span key={`paging-span-`+i}  className="page-link">{i}</span></li>
        return <li key={`paging-li-`+i} className="page-item"><a key={`paging-span-`+i} className="page-link" name="page" data-value={i==='First'?1:(i==='Last'?parseInt(props.page.total):i)} onClick={props.handleClick} href={{javascript:0}}>{i}</a></li>
    }

    return(
        <>
            <hr/>
            {props.loading === true?
            <ul className="pagination float-right">
                <li key={`paging-first`} className="page-item disabled"><span className="page-link">First</span></li>
                <li key={`paging-last`} className="page-item disabled"><span className="page-link">Last</span></li>
            </ul>
            :
            <ul className="pagination float-right">
                <li key={`paging-total-message`}><p className="m-2">Showing <strong>{props.page.current}</strong> page of <strong>{props.page.total}</strong> pages </p></li>
                {pages(parseInt(props.page.current), parseInt(props.page.total)).map((i)=>(
                   page(i)
                ))}
            </ul>
            }
        </>
    )
}

export default Paging;