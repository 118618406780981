import React, { useEffect, useState } from 'react'
import axios from 'axios';
import List from '../List';
//import Paging from '../Paging';
import  Breadcrumb from '../Breadcrumb';
//import ListFilters from '../ListFilters';
import {toast} from 'react-toastify';  

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

let params = {
    page:1,
    size: 100,
    order : '',
    orderdir:-1,
    search: '',
    startdate: defaultStartEndDate(),
    enddate: defaultStartEndDate(),
}

function defaultStartEndDate(){
    let d = new Date();
    d.setDate(d.getDate() - 1)
    return d.getFullYear()+'-'+(d.getMonth() < 10?('0'+(d.getMonth()+1)):(d.getMonth()+1))+'-'+(d.getDate() < 10?('0'+(d.getDate())):(d.getDate()))
}

function startDate() {
    let d = new Date();
    d.setDate(d.getDate() - 1)
    return d
}

function endDate() {
    let d = new Date();
    d.setMonth(d.getMonth() - 3)
    return d
}

const Corporate = () => {
    document.title = 'TV9 Portal: Story & Video Usage'
    const [loading, setLoading] = useState(true)
    const [sparams, setSparams] = useState(params);
    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });
    const [videolist, setVideoList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });
    
    const getList = async () => {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/reports/story-usage', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            try{
                toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            }catch(ee) {
                toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            }            
            setLoading(false)
        }
    }

    const getVideoList = async () => {
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/reports/video-usage', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setVideoList(response.data);
        } catch (e) {
            try{
                //console.error(e.response.status, e.response.statusText);
                toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            }catch(ee) {
                toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            }
        }
    }

    const handleApply = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: d.startDate.format("YYYY-MM-DD"),
                enddate: d.endDate.format("YYYY-MM-DD"),
            }
        })
        params['startdate'] = d.startDate.format("YYYY-MM-DD")
        params['enddate'] = d.endDate.format("YYYY-MM-DD")
        params['page'] = 1
        getList();
        getVideoList();
    }

    const handleCancel = (e, d) => {
        setSparams(prevState =>  {
            return {
                ...prevState,
                startdate: '',
                enddate: '',
            }
        })
        params['startdate'] = ''
        params['enddate'] = ''
        params['page'] = 1
        getList();
        getVideoList();
    }

    useEffect(() => {
        getList()
        getVideoList()
    }, []);

    return (
        <>
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Reports', url:'/reports/sroty-usage'}, {name:'Story & Video Usage', url:''}]}/>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        News
                                        <form className="form-inline float-right" onSubmit={e => { e.preventDefault(); }}>
                                            <div className="form-group">
                                                <DateRangePicker name="date" initialSettings={{ startDate: new Date(), endDate:  new Date(), minDate: endDate(), maxDate: startDate() }} onApply={handleApply} onCancel={handleCancel}>
                                                    <span className="btn btn-info">{sparams.startdate && sparams.enddate? sparams.startdate +' to '+sparams.enddate:'Date'}</span>
                                                </DateRangePicker>&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </form>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <table className="table bordered">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Posts Summary</th>
                                                <th className="text-center">Video Summary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <div className="table-responsive">
                                                    <List list={list} loading={loading} heading={[
                                                            {name:'#', field:'', width:''},
                                                            {name:'Name', field:'sitename',},
                                                            {name:'Target', field:'target',}, 
                                                            {name:'Achieved', field:'achieved',},
                                                            {name:'Achievement %', field:'achieved_percentage',},
                                                            ]}
                                                        />
                                                </div>   
                                            </td>
                                            <td>
                                            <div className="table-responsive">
                                                <List list={videolist} loading={loading} heading={[
                                                        {name:'#', field:'', width:''},
                                                        {name:'Name', field:'sitename',},
                                                        {name:'Target', field:'target',}, 
                                                        {name:'Achieved', field:'achieved',},
                                                        {name:'Achievement %', field:'achieved_percentage',},
                                                        ]}
                                                    />
                                            </div>    
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Corporate;