import React from 'react';

const ListFilters = (props) => {
    return(
        <>
            <div className="form-group">
                <label htmlFor="inlineFormInputGroup" className="sr-only">Search</label>
                <input name="search" value={props.params.search} onChange={props.handleClick} className="mr-3 form-control" placeholder="Search"/>
            </div>
            <div className="form-group">
                <label htmlFor="inlineFormInput" className="sr-only">Page Size</label>
                {/* <span style={{fontWeight:'normal'}}>Page Size</span>&nbsp; */}
                <select name="size" value={props.params.size} onChange={props.handleClick} className="mr-3 form-control">
                    <option value="5">5 Items</option>
                    <option value="10">10 Items</option>
                    <option value="20">20 Items</option>
                    <option value="30">30 Items</option>
                    <option value="40">40 Items</option>
                    <option value="50">50 Items</option>                    
                </select>
            </div>  
        </>
    )
}

export default ListFilters;