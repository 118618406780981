import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import  Breadcrumb from '.././Breadcrumb';
import axios from 'axios';

const Add = (prpos) => {
    const [form, setForm] = useState({
            title: '',
            shorttitle : ''
    })
    const [formerror, setFormError] = useState('')
    const clicked = (e) => {
        e.preventDefault();
        let name = e.target.name
        let value = e.target.value
        setForm(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const saveForm = async (e) => {
        e.preventDefault();
        if(form.title === '') {
            setFormError('Please enter title');
            return false;
        }
        if(form.shorttitle === '') {
            setFormError('Please enter short title');
            return false;
        }
        await axios.post(
            localStorage.getItem('basurl')+'/apis/type', form, {headers:{'Authorization':'Token '+localStorage.getItem("token")}}  
        ).then(response=>{
            console.log(response)
        }).catch(error => {
            let err = error.response.data;
            if (err.detail) {
                setFormError(err.detail)
            }
            return;
        }); 
    }

    return (
        <>
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'Settings', url:'/setting/type'}, {name:'Type', url:'/setting/type'},{name:'Add', url:''}]}/>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Add Type</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="col-lg-6 offset-lg-3"> 
                                            <div className="col-sm-10 offset-sm-2">
                                                <span className="text-danger">{formerror}</span>
                                            </div>
                                            <form className="form-horizontal" onSubmit={saveForm}>
                                                <div className="form-group row">
                                                    <div className="col-sm-2"> 
                                                        <label>Title</label>
                                                    </div>
                                                    <div className="col-sm-10">
                                                        <input id="inputHorizontalSuccess" type="text" name="title" value={form.title} onChange={clicked} maxLength={50} placeholder="Title" className="form-control form-control-success" />
                                                        <small className="form-text"></small>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-2"> 
                                                        <label>Short Title</label>
                                                    </div>
                                                    <div className="col-sm-10">
                                                        <input id="inputHorizontalWarning" type="text" name="shorttitle" value={form.shorttitle} onChange={clicked} maxLength={30} placeholder="Short Title" className="form-control form-control-warning" />
                                                        <small className="form-text"></small>
                                                    </div>
                                                </div>
                                                <div className="form-group row">       
                                                    <div className="col-sm-10 offset-sm-2 text-right">
                                                        <NavLink to="/setting/type" className="btn btn-secondary">Back</NavLink> &nbsp;&nbsp;
                                                        <input type="submit" value="Save" className="btn btn-primary" />
                                                    </div>
                                                </div>
                                            </form>                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}
export default Add;