import React, { useEffect, useState, useLayoutEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState({ username: '', password: '', error: '' })
    const [formerror, setFormError] = useState('')
    const [toppeding, setToppeding] = useState(0)

    const changeData = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setLogin(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const removeError = (e) => {
        setFormError('')
    }

    const getLogin = async (e) => {
        e.preventDefault();
        if (login.username !== '' && login.password !== '') {
            try {
                setLoading(true);
                await axios.post(
                    localStorage.getItem('basurl') + '/authenticate', {
                    username: ((login.username.split('@')).length > 0)?(login.username.split('@')[0]):login.username,
                    password: login.password,
                }
                ).then(response => {
                    localStorage.setItem("token", response.data.token);
                    axios.get(
                        localStorage.getItem('basurl') + '/authenticated', {
                        params: { 'Authorization': 'Token ' + response.data.token },
                        headers: { 'Authorization': 'Token ' + response.data.token }
                    }
                    ).then(response => {
                        localStorage.setItem("user", JSON.stringify([...response.data.results].shift()));
                        window.location.href = '/';
                    }).catch(error => {
                        let err = error.response.data;
                        if (err.non_field_errors) {
                            setFormError(err.non_field_errors)
                        }
                        return;
                    });
                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    try {
                        let err = error.response.data;
                        if (err.non_field_errors) {
                            setFormError(err.non_field_errors)
                        }
                    } catch (err) {
                        toast.error('Oops! there are some error (' + err + ') in this process. Please try after some time.');
                        console.error(error.message);
                        setFormError(error.message)
                    }
                    return;
                });
            } catch (err) {
                setLoading(false);
                toast.error('Oops! there are some error (' + err + ') in this process. Please try after some time.');
                console.error(err.message);
                setFormError(err.message)
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token") && localStorage.getItem("user")) {
            window.location.href = '/';
        }
    })

    useLayoutEffect(() => {
        setToppeding(((document.documentElement.clientHeight / document.documentElement.scrollHeight) * 100) / 5)
    }, [])

    return (
        <>
            <div className="page login-page">
                <div className="container">
                    <div className="form-outer text-center align-items-center" style={{ padding: parseInt(toppeding - 10) + '% 0' }}>
                        <div className="form-inner">
                            <div className="logo text-uppercase"><strong className="text-primary">TV9</strong><span>CMS</span></div>
                            <span className="text-danger">{formerror}</span>
                            <form method="get" className="text-left form-validate" onSubmit={getLogin}>
                                <div className="form-group-material">
                                    <input id="login-username" type="text" name="username" onFocus={removeError} onKeyUp={changeData} required data-msg="Please enter your login id" className="input-material" />
                                    <label htmlFor="login-username" className="label-material"><strong>Login-id<em style={{ color: 'red', fontSize: '23px', lineHeight: '1px' }}>*</em></strong></label>
                                </div>
                                <div className="form-group-material">
                                    <input id="login-password" type="password" name="password" onFocus={removeError} onKeyUp={changeData} required data-msg="Please enter your password" className="input-material" />
                                    <label htmlFor="login-password" className="label-material"><strong>Password<em style={{ color: 'red', fontSize: '23px', lineHeight: '1px' }}>*</em></strong></label>
                                </div>
                                <div className="form-group text-center">
                                    {loading ? <div className="spinner-border text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : <button type="submit" className="btn btn-primary">Login</button>}
                                </div>
                            </form>
                            <a href="/forgot-password" className="forgot-pass">Forgot Password?</a><small>Do not have an account? </small><a href="/signup" className="signup">Signup</a>
                        </div>
                        <div className="copyrights text-center">
                            <p>TV9 <a href={{ javascript: 0 }}>Digital Team</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;