import React, { useEffect, useState } from 'react';
import axios from 'axios';
import List from '../List';
import Paging from '../Paging';
import  Breadcrumb from '../Breadcrumb';
import ListFilters from '../ListFilters';
import {toast} from 'react-toastify';  

let params = {
    page:1,
    size: 20,
    order : '',
    orderdir:-1,
    search: '',
}

const SopManuals = () => {
    document.title = 'TV9 Portal: SOP Manuals'
    const [loading, setLoading] = useState(true)
    const [sparams, setSparams] = useState(params);
    const [list, setList] = useState({
        page:{
            size: params.size,
            total: 0,
            current: 1
        },
        count: 0,
        results:[]
    });
    
    const getList = async () => {
        setLoading(true)
        try {
            const response = await axios(
                localStorage.getItem('basurl') + '/sops/manuals-list', {
                    headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    params: params,
                }
            );
            setLoading(false)
            setList(response.data);
        } catch (e) {
            try{
                toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            }catch(ee) {
                toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            }            
            setLoading(false)
        }
    }

    
    const handleClick = (e) => {
        e.preventDefault();
        let name = e.target.name
        if (name !== 'page' && params.page > 1) params['page'] = 1
        let value = (e.target.value?e.target.value:e.target.dataset.value)
        setSparams(prevState =>  {
            return {
                ...prevState,
                [name]:value
            }
        })
        params[name] = value
        getList();
    }

    useEffect(() => {
        getList()
    }, []);

    return (
        <>
            <Breadcrumb items={[{name:'Home', url:'/'}, {name:'SOP Manuals', url:'/sops/manuals/list'}, {name:'List', url:''}]}/>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        News
                                        <form className="form-inline float-right" onSubmit={e => { e.preventDefault(); }}>
                                            <ListFilters handleClick={handleClick} params={sparams}/>
                                        </form>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <List list={list} loading={loading} heading={[
                                                {name:'#', field:'', },
                                                {name:'Name', field:'name', },
                                                {name:'Path', field:'path', link:true, target:'_blank', display:'name'},
                                                {name:'Published', field:'createdat',}]}
                                            />
                                        <Paging handleClick={handleClick} page={list.page} loading={loading}/>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SopManuals;