import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import ShowMore from 'react-show-more';
//import { toast } from 'react-toastify';

import ReactPortalHint  from "react-portal-hint";
import "react-portal-hint/dist/default.css";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
//import { NavLink } from 'react-router-dom';

const List = (props) =>{
    const [lightbox, setLightbox] = useState({isOpen:false, src:"", text:"", index:0, });

    const copyToClipboard = (text) => {
        let text1 = text.replace(/<br\s*\\?>|<br\/>/g, "\r\n").replace(/<\/p>/g, "\r\n").replace(/<p>/g, "");
        if(navigator.clipboard) {
            navigator.clipboard.writeText(text1);
        } else {
            const el = document.createElement('textarea');
            el.value = text1;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
        //toast('Copied', {autoClose: 1500});
    }
	
	function changeUserStatus(id,userstatus){
		console.log(id);
		console.log(userstatus);
		
		
		// let paramsstatus = {
			// id:intid,
			// status:userstatus
		// }
	
        // try {
            // const response = await axios(
                // localStorage.getItem('basurl') + '/mojo/users', {
                    // headers:{'Authorization':'Token '+localStorage.getItem("token")},
                    // params: paramsstatus,
                // }
            // );
        // } catch (e) {
            // try{
                // //console.error(e.response.status, e.response.statusText);
                // toast.error('Oops! there are some error (' +e.response.statusText+') with status code ('+e.response.status+')');
            // }catch(ee) {
                // toast.error('Oops! there are some error (' +e+') in this process. Please try after some time.');
            // }
        // }
    }

    const replaceinstr = (string, from, to) => {
        return string.replaceAll(from, to);
    }
    
    const getTd = (item, head, h, i) => {
        if(head.name === '#') {
            return <th key={`list-item`+i+`-`+h} scope="row">{((parseInt(props.list.page.current)*parseInt(props.list.page.size)) -parseInt(props.list.page.size)) +(i+1)}</th>
        } else if(head.issize) {
            let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            let bytes = item[head.field];
            let size = '';
            if (bytes === 0) {
                size = '0 Byte';
            }
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            size = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            return <td key={`list-item`+i+`-`+h}>{size}</td>  
        } else if(head.permission) {
            return (<td key={`list-item`+i+`-`+h}>
                {head.permission.view? <Link to="#" data-value={item[head.field]} onClick={head.viewItem}><i data-value={item[head.field]} className="fa fa-eye" aria-hidden="true"></i></Link>:'--'}
                {head.permission.edit? <>&nbsp;|&nbsp;<Link to="#" data-value={item[head.field]} onClick={head.editItem}><i data-value={item[head.field]} className="fa fa-edit" aria-hidden="true"></i></Link></>:' | --'}
                {head.permission.delete? <>&nbsp;|&nbsp;<Link to="#" data-value={item[head.field]} onClick={head.deleteItem}><i data-value={item[head.field]} className="fa fa-trash" aria-hidden="true"></i></Link></>:' | --'}
            </td>)
        } else if(head.lightbox) {
            if(head.download) {
                if(item[head.field].indexOf('https://') < 0 ) {
                    if(item[head.typefield] === 'video') {
                        return (<td className="feedvideo" key={`list-item`+i+`-`+h}>
                            <video controls style={{width:'150px',cursor:"pointer"}} src={`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`}>
                                <source src={`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> 
                        </td>)
                    } else {
                        return (<td className="feedvedioaction" key={`list-item`+i+`-`+h}>
                            <div>
                                <img onClick={()=>{setLightbox({isOpen:true, src: `https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`, text:item[head.captionfield],index:i })}} alt={item[head.field]} title={item[head.field]} src={`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`}/>
                            </div>
                            <div>
                                <a title="Download Image" href={`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`} target="_blank" rel="noopener noreferrer" download>
                                    <i className="fa fa-download"/>
                                </a><br/><br/>
                                {(head.downloadvideo)?
                                    <a title="Download Video" href={`https://apis.tv9.com/feeds/download?name=${item[head.field]}&source=afp&type=video`} target="_blank" rel="noopener noreferrer" download>
                                        <i className="fa fa-video-camera" aria-hidden="true"></i>
                                    </a>
                                    : ''
                                }
                            </div>                            
                        </td>)   
                    }           
                } else {
                    if(item[head.typefield] === 'video') {
                        return (<td className="feedvideo" key={`list-item`+i+`-`+h}>
                            <video controls src={`${item[head.field]}`}>
                                <source src={`${item[head.field]}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> 
                        </td>)
                    } else {
                        return (<td className="feedimageaction" key={`list-item`+i+`-`+h}>
                            <div style={{float:'left', width:'75%'}}>
                                <img onClick={()=>{setLightbox({isOpen:true, src: `${item[head.field]}`, text:item[head.captionfield], index:i})}} style={{maxWidth:'150px',cursor:"pointer"}} alt={item[head.field]} title={item[head.field]} src={`${item[head.field]}`}/>
                            </div>
                            <div style={{float:'right', textAlign:'center'}}>
                                <a title="Download Image" href={`${item[head.field]}`} target="_blank" rel="noopener noreferrer" download>
                                    <i className="fa fa-download"/>
                                </a>
                            </div>
                        </td>)  
                    }
                }                
            } else {
                if(item[head.field].indexOf('https://') < 0) {
                    if(item[head.typefield] === 'video') {
                        return (<td className="feedvedio" key={`list-item`+i+`-`+h}>
                            <video controls src={`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`}>
                                <source src={`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> 
                        </td>)
                    } else {
                        return (<td className="feedimage"  key={`list-item`+i+`-`+h}>
                            <img onClick={()=>{setLightbox({isOpen:true, src:`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`, text:item[head.captionfield], index:i})}} style={{width:'160px',cursor:"pointer"}} alt={item[head.field]} title={item[head.field]} src={`https://d2wxrsukxlxqim.cloudfront.net/${item[head.field]}`}/>
                        </td>)  
                    }
                } else {
                    if(item[head.typefield] === 'video') {
                        return (<td className="feedvedio" key={`list-item`+i+`-`+h}>
                            <video controls src={`${item[head.field]}`}>
                                <source src={`${item[head.field]}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> 
                        </td>)
                    } else {
                        return (<td className="feedimage" key={`list-item`+i+`-`+h}>
                            <img onClick={()=>{setLightbox({isOpen:true, src:`${item[head.field]}`, text:item[head.captionfield], index:i})}} alt={item[head.field]} title={item[head.field]} src={`${item[head.field]}`}/>
                        </td>) 
                    }
                }
            }  
        } else if(head.copyToClipboard && head.ShowMore) {
            return (
                <td key={`list-item`+i+`-`+h}>
                    <ReactPortalHint content="Copied.." events={['click']} place="right" >
                        <button style={{cursor:'pointer',float:'right',border: 'none', margin: '0', padding: '0', background:'white', outline:'none', width: '0px'}} title="Copy to clipboard">
                            <i className="fa fa-clipboard" aria-hidden="true" onClick={()=>{copyToClipboard(item[head.field])}} ></i>                        
                        </button>              
                    </ReactPortalHint>                      
                    <ShowMore lines={4} more='More' less='Less' anchorClass='width-97'>
                        <p title={item[head.field]} dangerouslySetInnerHTML={{__html: ((head.replace)?(replaceinstr(item[head.field], head.replace[0], head.replace[1])):(item[head.field]))}}></p>
                    </ShowMore>                                                                    
                </td>
            )
        } else if(head.ShowMore) {
            return (
                <td key={`list-item`+i+`-`+h}>
                    <ShowMore lines={4} more='More' less='Less' anchorClass=''>
                        <p title={item[head.field]} dangerouslySetInnerHTML={{__html: ((head.replace)?(replaceinstr(item[head.field], head.replace[0], head.replace[1])):(item[head.field]))}}></p>
                    </ShowMore>                                                                    
                </td>
            )
        }else if(head.OnOff) {
			if (item[head.field]==1){
				return (
					<td key={`list-item`+i+`-`+h}>
						<label class="switch">
						  <input type="checkbox" checked  onClick={()=>{changeUserStatus(item[head.field],item[head.field])}} ></input>
						  <span class="slider round"></span>
						</label>
					</td>
				)	
			}
			else{	
				return (
					<td key={`list-item`+i+`-`+h}>
						<label class="switch">
						  <input type="checkbox"  onClick={()=>{changeUserStatus(item[head.field],item[head.field])}} ></input>
						  <span class="slider round"></span>
						</label>
					</td>
				)
			}
			console.log(item[head.field]);
            return (
                <td key={`list-item`+i+`-`+h}>
					<label class="switch">
					  <input type="checkbox" ></input>
					  <span class="slider round"></span>
					</label>
                </td>
            )
        } else if(head.copyToClipboard) {
            return (<td key={`list-item`+i+`-`+h}>
                <ReactPortalHint content="Copied.." events={['click']} place="right" >
                    <button style={{cursor:'pointer',float:'right',border: 'none', margin: '0', padding: '0', background:'white', outline:'none'}} title="Copy to clipboard">
                        <i className="fa fa-clipboard" aria-hidden="true" onClick={()=>{copyToClipboard(item[head.field])}}></i>                        
                    </button>              
                </ReactPortalHint>                
                <p title={item[head.field]}>{((head.replace)?(replaceinstr(item[head.field], head.replace[0], head.replace[1])):(item[head.field]))}</p>
            </td>)
        } else if(head.isdate) {
            return <td key={`list-item`+i+`-`+h}>{new Date(item[head.field]).toLocaleString('en-IN', { timeZone: (head.timeZone)?head.timeZone:'Asia/Kolkata' })}</td>  
        
        } else if(head.yesno) {
            return <td key={`list-item`+i+`-`+h}>{(item[head.field])?'Yes':'No'}</td>  
        } else if(head.link) {
            return <td key={`list-item`+i+`-`+h}><a href={item[head.field]} target={head.target}>{item[head.display]}</a></td>  
        } else {
            return <td key={`list-item`+i+`-`+h}>{(item[head.field])?((head.replace)?(replaceinstr(item[head.field], head.replace[0], head.replace[1])):(item[head.field])):'--'}</td>
        }
    }

    return(
        <>
            <table className="table">
                <thead>
                    <tr>
                        {props.heading.length===0?
                            <th key={`list-heading-`+1}>Please provide heading</th>
                        :
                        props.heading.map((head, h) =>(
                            <th key={`list-heading-`+h} className={head.class} width={head.width}>{head.name}</th>
                        ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {props.loading === true?
                    <tr>
                        <th className="text-center" colSpan={10}>Loading...</th>                                                    
                    </tr>
                    :
                    props.list.results.length===0? 
                    <tr>
                        <th className="text-center" colSpan={10}>No Data</th>                                                    
                    </tr>:
                    props.list.results.map((item, i)=>(
                        <tr key={`tbl-`+item.id}>
                            {props.heading.length===0?
                            <td>Please provide heading</td>
                            :
                            props.heading.map((head, h) =>(
                                getTd(item, head, h, i)
                            ))
                            }                                       
                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {lightbox.isOpen?
                <Lightbox mainSrc={lightbox.src} onCloseRequest={() => {setLightbox({isOpen:false, src:'', text:'',index:0})}} imageCaption={lightbox.text} options={{showDownloadButton:true}}/>
            :null}
        </>
    )
}
export default List;