import React from 'react';
import { NavLink } from 'react-router-dom';

const Breadcrumb = (props) => {
    return (
        <>
            <div className="breadcrumb-holder">
                <div className="container-fluid">
                    <ul className="breadcrumb">
                        {props.items.map((item, i)=>(
                            item.url? <li key={`breadcrumb-`+i} className="breadcrumb-item"><NavLink to={item.url}>{item.name}</NavLink></li>:<li key={`breadcrumb-`+i} className="breadcrumb-item active">{item.name}</li>                        
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Breadcrumb;